<template>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                id="LoginToolbar"
              >
                <v-toolbar-title style="color: white;">New Website</v-toolbar-title>
                <div class="flex-grow-1"></div>

              </v-toolbar>
              <!-- TEST 2-->
              <v-card-text>
                <v-form
                  ref="form"
                  lazy-validation>
                  <v-row>
                    <v-col>
                    
                         <v-text-field
                          v-model="name"
                          prepend-icon="mdi-account"
                          :rules="[() => !!name || 'Domain Name is required' ]"
                          label="Domain Name (including TLD - .com, .net , etc)"
                          required
                        />
                      
                    </v-col>
               
                    </v-row>
         
                  <v-select
                    v-if="account_type !='PBN'"
                   item-text="text"
                    item-value="value"
                    :items="regions"
                    v-model="region"
               
                    prepend-icon="mdi-account-multiple"
                    label="Region"
                    dense
                    :rules="[() => !!region || 'Region is required']"
                    required
                  ></v-select>  
                   <v-select
                    v-if="account_type =='PBN'"
                    item-text="DISTINCT"
                    item-value="DISTINCT"
                    :items="ipGeos"
                    v-model="ipGeo"
                    prepend-icon="mdi-account-multiple"
                    label="IP GEO"
                    dense
                    :rules="[() => !!ipGeo || 'IP Region is required']"
                    required
                  ></v-select>  
                    <v-select
                    item-text="text"
                    item-value="value"
                    :items="types"
                    v-model="type"
                 
                    prepend-icon="mdi-account-multiple"
                    label="New/Existing Install"
                    dense
                    :rules="[() => !!type || 'Type is required']"
                    required
                  ></v-select>
                     <v-select
                    item-text="text"
                    item-value="value"
                    :items="php_versions"
                    v-model="php_version"
                    prepend-icon="mdi-account-multiple"
                    label="PHP Version"
                    dense
                    :rules="[() => !!php_version || 'Php Version is required']"
                    required
                  ></v-select>
                       <v-checkbox
                        v-model="www"
                        label="www"
                       ></v-checkbox>
                  </v-form>
                    <p class="font-weight-light">
                    </p>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="createSite">Create</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbar"
          :color="color"
          :top='true'
        >
    
          {{ snackbarMessage }}
        
       <template v-slot:action="{ attrs }">
          <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="$router.push('/job/'+job_uuid)"
        >
          VIEW
        </v-btn>
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
        </v-snackbar>
     </div>

             <v-overlay
          v-if="overlay"
          :value="overlay"
          z-index=0 
         >

        <v-row class="text-center">
          <v-col text-center>
            <v-progress-circular
            align-center
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
               Creating your site, Please Wait!
          </v-row>
       </v-overlay>
      </v-container>
</template>

<style>

</style>
<script>
import jwt from 'jsonwebtoken'

export default {
    data: function () {
        return {
            name: '',
            overlay: false,
            region: '',
            type: '',   
            www: false,
            php_version: '',
            php_versions: [],
            types: [ { text: "New Wordpress Installation (domain.cubixd.com)", value: "new"}, { text: "Empty Installation", value: "existing"}], 
            regions: [ ], //{text: "Frankfurt (DE)", value: "DE "}, {text: "Amsterdam (NL)", value: "AMS "}
            snackbarMessage: '',
            snackbar: false,
            color: 'general',
            account_type: "",
            ipGeo: "",
            ipGeos: [],
            job_uuid: "",
        }
    },
    created: function (){
            var token = jwt.decode(this.$store.state.token)
            this.account_type = token.account_type

            if(this.account_type == "PBN"){
              //GET IP GEOS
                 this.$store.dispatch('getIpGeos')
                 .then(resp => {
                    this.ipGeos = resp.data.data
                    console.log(resp.data.data)

                }).catch ((e) =>{

                    console.log(e)
                })
            }

            //GET Regions from Api and set this.regions to the returned data
            this.$store.dispatch('getRegions')
            .then(resp => {
                resp.data.forEach(region => {
                    this.regions.push({value: region.id, text: region.city + " (" + region.country + ")"})
                }); 
                
              // window.open(resp.data)



            })
            //GET PHP Versions 
            this.$store.dispatch('getPhpVersions')
            .then(resp => {
                resp.data.forEach(version => {
                    this.php_versions.push({value: version.version, text: version.text })
                }); 
                
            })


    },
    methods: {
        createSite() {
            if(this.$refs.form.validate()){

               this.overlay = true
              //Open Dialog with Terminal Like layout to receive socket.io data

              let data = {
                  name: this.name,
                  region: this.region,
                  type: this.type,
                  php_version: this.php_version,
                  www: this.www,
                  ipGeo: this.ipGeo
              }

        
              this.$store.dispatch('createSite' , data)
              .then(response => {

                this.snackbarMessage = response.data.msg;
                this.job_uuid = response.data.job_uuid
                this.snackbar = true
                this.overlay = false
                this.$refs.form.reset()

              })
              .catch(err => {        
                console.log(err.response.data)
                    this.snackbarMessage = err.response.data.msg;
                    this.snackbar = true
                    this.overlay = false

              })

            }else{
                this.snackbarMessage = "Kindly fill in the missing fields.";
                this.snackbar = true

            }
           
        },
        metaInfo () {
            return {
            title: 'Cubixd | New Site'
            }
        }
    }
}
</script>
